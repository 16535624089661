/* Stil pentru containerul principal */
.car-filters {
  display: flex;
    justify-content: space-between;
  flex-direction: column; /* Schimbăm direcția de afișare a conținutului */
  align-items: center; /* Centrăm conținutul pe axa orizontală */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 0 auto;
  width: 50%;
  margin-bottom: 20px;
  margin-top: 20px;
}

/* Stil pentru zona de filtre */
.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
}

/* Stil pentru fiecare select */
.personalizare{
  width: calc(30% - 10px);
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  margin: 0;
}

.personalizare-input {
  width: calc(60% - 5px);
  /*padding: 8px;*/
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  /*  margin-right: 10px;*/
  margin: 0;
}


/* Stil pentru liniuță */
.dash {
  margin: 0 5px;
}

/* Stil pentru buton */
.filter-buton {
  width: 100%;
  text-align: center;
}

.personalizare-buton {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Adăugăm o tranziție lină pentru culoarea butonului */

  /* Efect de umbră la hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.personalizare-buton:hover {
  background-color: #0056b3; /* Schimbăm culoarea la hover */
}
.input-with-dash {
  display: flex;
  align-items: center;
  width: 40%;
}

.input-with-dash {
  width: calc(55% - 5px); /* Ajustează dimensiunea pentru fiecare input */
}

.input-with-dash .dash {
  margin: 0 5px;
}

.input-with-dash-titlu {
  position: relative;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: normal;
}

/*.cm3 {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: 0;*/
/*}*/


.filter-group {
  flex: 1; /* Adjust the flexibility of the element within its container */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  margin-bottom: 20px;
}


