/* Add your global CSS styles here */

.profile--container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.profile--info {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 25px;
}

.create-profile {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}
.sub-tit {
  font-size: 10px;
  margin-bottom: 20px;
  text-align: center;
  color: #ef0404;
}

.form--input {
  width: 90%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-left: 20px;
}

.form---input {
  width: 94%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-bottom: 15px;
  margin-left: 20px;
}

.form--button {
  background-color: #007bff;
  color: #fff;
  width: 94%;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 20px;
}

.form--button:hover {
  background-color: #0056b3;
}

.avatarr-containerr {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.avatar {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
}

/* Stil pentru starea activă a Dropzone */
.avatarr-containerr.active {
  border: 2px dashed #007bff;
}

/* Design responsiv pentru ecrane mai mici */
@media (max-width: 468px) {
  .profile--container {
    max-width: 100%;
    margin: 20px 10px;
  }

  .form--input {
    width: 100%;
    margin-left: 0;
  }

  .form---input {
    width: 100%;
    margin-left: 0;
  }

  .form--button {
    width: 100%;
    margin-left: 0;
  }

  .avatarr-containerr {
    margin-left: 0;
    margin-right: 0;
  }
}
