/* ResetPassword.css */

.reset-password-container {
  text-align: center;
  margin: 20px auto;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #f9f9f9;
}

.reset-password-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  margin-right: 22px;
}

.form-labell {
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
}

.form-inputt {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 100px;
  font-size: 16px;
}

.reset-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.reset-button:hover {
  background-color: #0056b3;
}

.message {
  font-size: 16px;
  margin-top: 20px;
  color: #ff0000; /* Puteți alege propria culoare pentru mesajele de eroare */
}

/* Design responsiv pentru ecrane mai mici */
@media (max-width: 368px) {
  .reset-password-container {
    max-width: 100%;
    margin: 20px 10px;
  }

  .form-group {
    margin-right: 0;
  }

}

