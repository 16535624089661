/* Address.css */


.address-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.settings-panell {
  width: 20%;
  padding: 20px;
  /*background: linear-gradient(45deg, #007bff, #038109);*/
  margin: 20px;
}

.address-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.address-item p {
  margin: 10px 0;
}

.edit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #0056b3;
}

/* Stilurile pentru popup-ul de editare */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background-color: #fff;
  max-width: 400px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.edit-title {
    font-size: 20px;
    margin-bottom: 20px;
    margin-left: 130px;
}
.edit-input {
  width: 80%;
  padding: 10px;
  margin-bottom: 15px;
    margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.update-button-ad {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
    margin-left: 80px;
}

.close-button-ad {
  background-color: #ccc;
  color: #fff;
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  cursor: pointer;
}

/*!* Media query pentru popup-ul de editare *!*/
/*@media (max-width: 600px) {*/
/*  .popup-content {*/
/*    max-width: 100%;*/
/*  }*/
/*}*/

@media (max-width: 568px) {
  .address-container {
    flex-direction: column;
    align-items: center;
  }

  .settings-panell {
    width: 80%;
  }

  .profile-info {
    margin: 10px 0;
    width: 100%;
  }

  .update-button-ad,
  .close-button-ad {
    margin-left: 0;
  }
}
