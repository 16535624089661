/* Change.css */

.custom-div-c {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.custom-form-c {
  background-color: #f0f0f0;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-wrapper {
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
}

.input-element {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 100px;
}

.inputlabel {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}

.password-togglee {
  position: absolute;
  top: 68%;
  right: 4px;
  left: 335px;
  transform: translateY(-50%);
  color: #007bff;
  cursor: pointer;
}

.password-togglee.active {
  color: #ce0d0d; /* Culoarea când este apăsat */
}

.submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 40px;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Stil pentru mesajele de succes și de eroare */
p {
  margin-top: 10px;
  font-weight: bold;
}

/* Design responsiv pentru ecrane mai mici */
@media (max-width: 368px) {
  .custom-div-c {
    height: auto;
  }

  .custom-form-c {
    max-width: 100%;
    margin: 20px 10px;
  }

  .input-wrapper {
    margin-right: 0;
  }

   .alert-success {
    top: 5%;
    max-width: 95%; /* Adjust the max-width for smaller screens */
    width: 95%; /* Adjust the width for smaller screens */
    margin-right: 100px;
  }
}

.alert-success {
  position: fixed;
  top: 110px;
  left: 89%;
  transform: translateX(-50%);
  background-color: #33cc33;
  color: white;
  padding: 40px 100px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
}