.product-list-com {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.product-card-com {
  width: 340px;
  margin: 10px;
  height: 250px;
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s;
  background-color: #f7f7f7;
  overflow: hidden;
}

.product-card-com:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.product-card-com img {
  width: 100%;
  height: 50%;
  border-radius: 10px 10px 0 0;
}