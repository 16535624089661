/* CSS pentru modal */
.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 70%; /* Lățimea modalului */
}

.modal-header {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-title {
  font-size: 20px;
  margin: 0;
}

.modal-body {
  padding: 20px;
  overflow-y: auto;
  max-height: 70vh; /* Înălțimea maximă a conținutului modalului */
}

/* CSS pentru butonul de închidere (close button) */
.modal-close-icon {
  font-size: 24px;
  cursor: pointer;
  margin-right: 20px;
  color: #000;
}

.modal-close-icon:hover {
  color: #f00;
}

.deletee-icon {
  font-size: 20px; /* Mărimea fontului */
  color: #f00; /* Culoarea iconiței */
  cursor: pointer; /* Transformă cursorul într-o mână pentru a indica faptul că iconița este clicabilă */
}

.deletee-icon:hover {
  color: #ff0000; /* Culoarea la hover (când utilizatorul trece cursorul peste iconiță) */
}