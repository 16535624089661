/* Styling for the comment container */
.comment-container {
  margin: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Styling for the textarea input */
textarea {
  width: 98%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Styling for the "Adăugați Comentariu" button */
button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

/* Styling for error messages */
.error {
  color: #ff0000;
  margin-top: 10px;
}

/* Styling for success messages */
.success {
  color: #007bff;
  margin-top: 10px;
}
