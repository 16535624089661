/* Car.css */

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.product-card {
  width: 340px;
  margin: 10px;
  height: 360px;
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s;
  background-color: #f7f7f7;
  overflow: hidden;
}

.product-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.container-images{
  margin-left: 10px;
  width: 300px;
  height: 210px;
  padding: 10px 0 0 10px;


}

.product-card img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}

.product-details {
  padding: 15px;
  text-align: center;
}

.product-name-car {
  font-size: 22px;
  color: #0c0c0c;
  margin-bottom: 5px;
}

.product-price {
  font-size: 14px;
  color: #ec0707;
  margin-bottom: 10px;
}

.price-number {
  color: #0c0c0c;
}

.product-info {
  font-size: 14px;
  color: #0a0a0a;
}

.gear-icon,
.fuel-icon {
  margin-right: 5px;
  color: #777;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button,
.page-numbers li {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination button:hover,
.page-numbers li:hover,
.page-numbers li.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.page-numbers {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.page-numbers li {
  font-size: 16px;
  font-weight: bold;
}

.page-numbers li.active {
  background-color: #007bff;
  color: #fff;
}

.error-message {
  width: 200px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.74);
  color: #dc2222;
  padding: 5px;
  border-radius: 5px;
  margin: 10px auto; /* Margin: sus/jos - 20px, stânga/dreapta - auto (pentru a centra pe orizontală) */
  text-align: center; /* Pentru a alinia textul în centru */
}
