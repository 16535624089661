.Service-container {
  position: relative;
  width: 100%;
}

.text-overlay {
  position: relative;
  padding: 20px;
}

.title-service {
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
}

.servicii-caruseli {
  width: 90%;
  margin: 0 auto;
}

.slide-container {
  max-width: 90%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 10px;
  border: 2px solid;
  border-radius: 15px;
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  background-color: #fff;
}

.slide-container:hover {
  transform: scale(1.05);
}

.slide-container p {
  max-width: 90%;
  margin: 30px auto;
  color: #0c0c0c;
  line-height: 1.4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 30;
  -webkit-box-orient: vertical;
}

/* Media Query for screens smaller than 768px (typically phones) */
@media (max-width: 768px) {
  .title-service {
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: 20px;
    margin-left: 10px;
  }

  .slide-container {
    width: 90%;
    max-width: none;
    height: 100px;
    border-radius: 5px;
  }

  .slide-container p {
    -webkit-line-clamp: 3;
    line-height: 1.2;
    margin: 5px auto;
  }
}
