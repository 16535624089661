.admin-row {
  display: flex;
  flex-direction: row; /* Afișează elementele într-un rând orizontal */

}

.admin-container {
  background-color: #f0f0f0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 15%;
  min-width: 200px; /* Setăm o lățime minimă pentru a păstra dimensiunea constantă */
  transition: width 0.3s;
  overflow: hidden;
}

.settings-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-bottom: 10px;
}

.settings-icon {
  margin-left: 10px;
}

.settings-admin {
  display: flex;
  flex-direction: column; /* Afișează butoanele într-o coloană */
  align-items: center;
  min-width: 100%;
}

.control-buttons-container {
  display: flex; /* Utilizăm Flexbox pentru alinierea orizontală */
  flex-direction: column; /* Afișează butoanele într-o coloană */
  align-items: center; /* Aliniem vertical butoanele și componenta */
  gap: 10px; /* Spațiere între butoane */
  margin-top: 10px; /* Adăugăm o margin-top pentru a separa butoanele de titlu */
}

.control-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.control-button:hover {
  background-color: #0056b3;
}


.control-button-tt {
  display: flex; /* Utilizăm Flexbox pentru alinierea orizontală */
  flex-direction: column; /* Afișează butoanele într-o coloană */
  align-items: center; /* Aliniem vertical butoanele și componenta */
  gap: 10px; /* Spațiere între butoane */
  margin-top: 10px; /* Adăugăm o margin-top pentru a separa butoanele de titlu */
}

.control-button-tc {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.control-button-tc:hover {
  background-color: #0056b3;
}