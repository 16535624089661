.register {
  max-width: 350px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.register-title {
  font-size: 28px;
  margin-bottom: 10px;
  text-align: center;
}

.form-elementt {
  margin-top: 20px;
  margin-bottom: 10px;
}

.label-register {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  margin-right: 22px;
  margin-left: 10px;
}

.input-register {
  width: 80%;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 100px;
  font-size: 16px;
}

.password-tog {
  cursor: pointer;
  position: relative;
  left: -15%;
  transform: translateX(-100%);
  font-size: 18px;
  right: 1px;
  color: #007bff; /* Culoarea inițială */
  transition: color 0.3s ease-in-out; /* Adaugă o tranziție pentru schimbarea culorii */
}

.password-tog.active {
  color: #ce0d0d;
}

.error {
  color: red;
  margin-top: 10px;
}

.button-register {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  width: 86%;
  margin-left: 10px;
}

.button-register:hover {
  background-color: #0056b3;
}

.login-link {
  margin-top: 20px;
  text-align: center;
}

.login-link a {
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
  font-weight: normal;
}

.login-link h1 {
  font-weight: normal; /* Face ca textul din h3 să nu fie îngroșat */
  font-size: 16px;
}

/* Design responsiv pentru ecrane mai mici */
@media (max-width: 368px) {
  .register {
    max-width: 100%;
    margin: 20px 10px;
  }

  .input-register {
    width: 100%;
  }

  .alert-error {
    top: 5%;
    max-width: 95%; /* Adjust the max-width for smaller screens */
    width: 95%; /* Adjust the width for smaller screens */
    /*margin-right: 100px;*/
  }
}


.alert-error {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff3333;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

