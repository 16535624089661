/* Style for custom modal */
.custom-modal {
  background-color: #fff; /* Background color */
  padding: 20px; /* Adjust padding as needed */
  border-radius: 8px;
  position: fixed;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%; /* Adjust the maximum width if needed */
  width: 400px; /* Set the width of the modal */
  max-height: 80%; /* Adjust the maximum height if needed */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
}

/* Style for modal components */
.custom-modal .modal-header {
  background-color: #0c0c0c;
  border-bottom: none;
}

.custom-modal .modal-footer {
  background-color: #f0f0f0;
  border-top: none;
}

/* Add these styles to modify the modal appearance */
.custom-modal {
  background-color: #fff; /* Change the modal background color */
  padding: 20px;
  border-radius: 10px;
}


/* Modify the styles for the modal header */
.custom-modal .modal-header {
  border-bottom: none; /* Remove the border bottom of the header */
}

/* Customize the modal title */
.custom-modal .modal-title {
  font-size: 24px;
  font-weight: bold;
  color: #2d4dff;
  margin-bottom: 15px;
}

/* Style the modal body */
.custom-modal .modal-body {
  padding: 20px 0; /* Add padding to the modal body */
}

/* Style the form elements inside the modal */
.custom-modal form {
  display: flex;
  flex-direction: column;
}

.custom-modal input,
.custom-modal select,
.custom-modal textarea {
  margin-bottom: 15px; /* Add space between form elements */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom-modal input[type="submit"],
.custom-modal button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.custom-modal input[type="submit"]:hover,
.custom-modal button:hover {
  background-color: #0056b3;
}

/* Add styles for the dropzone */
.custom-modal .dropzone {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}

 /*Style the close button*/
.custom-modal button[type="button"] {
  background-color: #dc3545;
}

.custom-modal button[type="button"]:hover {
  background-color: #c82333;
}
.custom-modal button[type="button1"] {
  background-color: #038109;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-modal button[type="button1"]:hover {
  background-color: #038109;
}

.modal-edit{
margin: 100px;
}

.custom-modal button[type="submit1"] {
  background-color: #038109;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-modal button[type="submit1"]:hover {
  background-color: #038109;
}


.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Alinează elementele pe axa orizontală în centru */
}

.image-wrapper {
  text-align: center; /* Alinează conținutul la centru */
  margin: 0 10px; /* Adaugă spațiu între fiecare imagine */
}

.img-update {
  width: 30%; /* Asigură că imaginea ocupă întreaga lățime a containerului său */
  height: auto; /* Păstrează proporțiile originale ale imaginii */
  display: block;
  margin-bottom: 10px;
}

.name-images {
  font-size: 10px;
  margin: 0; /* Elimină spațiul din jurul numelui imaginii */
}


.gallery-update {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 10px;
}

.image-item--update {
  position: relative;
  max-width: 150px;
}

.image-update {
  width: 100%;
  height: 70%;
  display: block;
  border-radius: 8px; /* Colțuri rotunjite pentru imagine */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Umbră pentru imagine */
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 4px 8px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.image-description--update {
  margin-top: 8px;
  font-size: 14px;
  color: #555;
  word-wrap: break-word; /* Permite textului să se împartă pe mai multe linii */
  max-height: 200px; /* Înălțimea maximă a descrierii */
  overflow: auto; /* Adaugă scrollbar în cazul în care textul depășește înălțimea */
}

