/* Comments.css */

.comments-container {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comments-header {
  font-size: 18px;
  margin-bottom: 10px;
}

.comments-list {
  list-style: none;
  padding: 0;
}

.comment-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.user-info {
  display: flex;
  /*align-items: center;*/
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details {
  display: flex;
  flex-direction: column;

}

.user-name {
  font-weight: bold;
}

.comment-date {
  font-size: 14px;
  color: #777;
}

.comment-text {
  text-indent: 0;
}
